import React from 'react';
export class CenteredContent extends React.Component {

  render() {

    return (
      <div>
        <section className="project-section section-gap">
          <div className="container">
            <div className="about-text">
              <h2>{this.props.heading}</h2>
              <p>{this.props.content}</p>
              <div className="text-center">
                <a href={this.props.action} className="_btn" style={{ 'backgroundColor': this.props.btnbgcolor, 'color': this.props.btncolor }}>{this.props.btnval}</a>
              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}
export default CenteredContent;
