import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Layout from "../components/layout";
import CenteredContent from "../components/CenteredContent";
import CalltoAction from "../components/CalltoAction";
import ColoredContent from "../components/ColoredContent";
import OverlappingContent from "../components/OverlappingContent";

import $ from "jquery/dist/jquery";

const ServicesTitleIconArray = [
  {
    serviceicon: "../../icon-window.png",
    alt: "window-cleaning",
    title: "Window Cleaning",
    href: "/services#window-cleaning",
  },
  {
    serviceicon: "../../icon-cleaning.png",
    alt: "rup",
    title: "Carpet Cleaning",
    href: "/services#carpet-cleaning",
  },
  {
    serviceicon: "../../icon-chair.png",
    alt: "ruchairp",
    title: "Contract Cleaning",
    href: "/services#contract-cleaning",
  },
  {
    serviceicon: "../../icon-sanitiser.png",
    alt: "SanitisingProducts",
    title: "Buy Products",
    href: "/contact",
  },
];

class IndexPage extends React.Component {
  componentDidMount() {
    $(window).on("scroll", function() {
      if ($(this).scrollTop() > 600) {
        $(".scroll-top").fadeIn(600);
      } else {
        $(".scroll-top").fadeOut(600);
      }
    });

    $(".scroll-top").on("click", function() {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        500
      );
      return false;
    });

    $("._scrollIcon a").click(function(e) {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#our-services").offset().top,
        },
        1000
      );
    });

    var $menu = $(".main-menu ul").clone();
    $("#mobile-menu").html($menu);
    $("#mobile-menu ul li:last-child").remove();

    $(".mobile-menu-icon").on("click", function() {
      $(".mobile-menu-wrap").toggleClass("mobile-menu-show");
    });

    $(".close-btn").on("click", function() {
      $(".mobile-menu-wrap").removeClass("mobile-menu-show");
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="google-site-verification"
            content="YAjmKXP8olHAA_JpbqTrbgHbFFvG4h8yvrpmb1lzL1w"
          />
          <title>
            GG Cleaning Ireland | Window Cleaning, Carpet Cleaning, Contract
            Cleaning in Castlebar, Ireland
          </title>
          <description>
            GG Cleaning, based in Castlebar, County Mayo was established in 2010
            by Gareth & Charlotte Gargan. Our family run business focuses on
            high quality cleaning that exceeds industry standards.
          </description>
        </Helmet>

        <div>
          {/* Start header section */}
          <section className="header-section">
            <div className="d-flex align-items-center">
              <div className="col-lg-12 header-left order-lg-1 order-md-2 order-2 pad0">
                <div className="home-banner">
                  <img src="../../main-banner.png" alt="mainbanner" />
                  <div className="home-title">
                    <h2>
                      Making your Business
                      <br /> and Home Shine, Since 2010
                    </h2>
                  </div>
                  <div className="bottom-scroll">
                    <h4>Find out how</h4>
                    <div className="_scrollIcon">
                      <a href="javascipt:void(0);">
                        <span className="vert-move" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End header section */}

          {/* Start about section */}

          <ColoredContent
            sectionbg="#D91F3D"
            btnbgcolor="#292E34"
            btncolor="#fff"
            headingcolor="#fff"
            contentcolor="#fff"
            heading="Our success is based on our dedication to high standards and professionalism"
            content="GG Cleaning, based in Castlebar, County Mayo was established in 2010 by Gareth &amp; Charlotte Gargan. Our family run business focuses on high quality cleaning that exceeds industry standards."
            action="/about"
            btnval="About Us"
          />

          {/* End about section */}

          {/* Start service section */}

          <OverlappingContent
            textbg="#292E34"
            titlecolor="#fff"
            overlapimg="../../middle-banner.png"
            arrowimg="../../arrow.png"
            ServicesTitleIcon={ServicesTitleIconArray}
          />

          {/* End service section */}

          {/* Start project section */}

          <CenteredContent
            btnbgcolor="#D91F3D"
            btncolor="#fff"
            heading="Our Projects"
            content="At GG Cleaning, we take pride in every window pane that we clean. Our goal is to have every project become a portfolio piece that we are proud of. While we can't show every one of our projects, here's a few great ones."
            action="/projects"
            btnval="Discover"
          />

          {/* End project section */}

          {/* Start request section */}

          <CalltoAction
            bgimage="../../footer-baner.png"
            btnbgcolor="#D91F3D"
            btncolor="#fff"
            heading={"Let us help your business shine"}
            action="/contact"
            btnval="Request a quote"
          />

          {/* End request section */}
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
